var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('div', {
    staticClass: "about-section"
  }, [_c('div', {
    staticClass: "jumbotron"
  }, [_c('h1', {
    staticClass: "display-4"
  }, [_vm._v("SOMEF Vider")]), _c('hr', {
    staticClass: "my-4"
  }), _c('p', [_vm._v(" With an increase of complex scientific software, researchers need to spend a large portion of their time searching for available software documentation and source code, and reading it to understand the software used and the proper way to cite it. ")]), _c('p', [_vm._v(" Somef Vider is a web application that retrieves the metadata of the given GitHub repository through the Software Metadata Extraction Framework (SOMEF) and shows it in a user-friendly way. ")]), _c('p', [_vm._v(" To use this tool the user must provide a valid GitHub URL and click the submit button, wait for the server response, and a table with all the metadata extracted will appear below the form. The most relevant metadata will show at the header of the table, such as title, license, las update, etc. For the rest of the fields, each one will be enclosed in a drop down card with the extraction method used and the confidence of the value. ")]), _c('p', [_vm._v(" Source code is available at this "), _c('a', {
    attrs: {
      "href": "https://github.com/SoftwareUnderstanding/SOMEF-Vider"
    }
  }, [_vm._v("GitHub repository")])])])]), _c('h2', {
    staticClass: "center"
  }, [_vm._v("Developers")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-4"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "card-title h5"
  }, [_vm._v("Victor Fernandez-Lancha Aranda")]), _c('p', {
    staticClass: "card-text"
  }, [_vm._v("v.faranda@alumnos.upm.es")])])])])]), _c('h2', {
    staticClass: "center"
  }, [_vm._v("Mentor")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-4"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "card-title h5"
  }, [_vm._v("Daniel Garijo")]), _c('p', {
    staticClass: "card-text"
  }, [_vm._v("daniel.garijo@upm.es")])])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }